@import url(sanitize.css);
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: #238;
  transition: 300ms ease-in;
}

a:hover, a:active {
  color: #4158cf;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 641px) {
  .page .page-contents {
    max-width: 800px;
  }
}

@media (max-width: 640px) {
  .page .page-contents {
    margin: 0 20px;
  }
}

header.global {
  height: 50px;
  text-align: center;
  background: #238;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

header.global h1 {
  font-size: 24px;
  line-height: 50px;
}

header.global h1 a {
  color: white;
  text-decoration: none;
}

footer.global {
  margin: 80px 0;
  text-align: center;
  color: #999;
  font-size: 9px;
}

@media (max-width: 640px) {
  footer.global {
    margin: 40px 0;
  }
}
